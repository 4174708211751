.App {
  text-align: center;
}

img[src="/whitebg2.png"],
img[src="/pfp.jpg"] {
  min-height: 20vmin;
  max-height: 25vmin;
  border-radius: 50%;
}

.App-header {
  background-color: #4e0072c0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
